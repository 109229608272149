.category-tag-container {
    display: flex;
    gap: 5px;
    background-color: #013774;
    padding: 5px;
    border-radius: 5px;
    align-items: center;
    height: 25px;
}
.category-tag-main {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}
.category-tag-input {
    background: transparent;
    border: none;
}
.category-tag-input {
    display: flex;
    align-items: flex-start;
    flex: 1;
}
.category-tag-input input {
    background-color: transparent;
    flex: 1;
    outline: none;
    border: 0;
}
.category-tag-input input:focus {
    border: none;
    background-color: transparent;
    border-width: 0px;
    border-color: transparent;
}
.tags-input {
    &:focus-within {
        box-shadow: rgba(0, 174, 239, 0.1) 0px 0px 2px 1px, rgb(54 74 103) 0px 0px 8px 2px;
    }
}
