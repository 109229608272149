.dashboad-header-container {
    position: relative;
    height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.header-first-portion {
    display: flex;
    gap: 16px;
    align-items: center;
}
.dashboard-header-data-labels {
    height: 35px;
    display: flex;
    align-items: center;
    padding: 0px 8px;
    border-radius: 4px;
    background-color: #005fcc30;
    color: #00aeef;
    font-size: 12px;
    font-weight: 500;
}
.dashboard-header-btn {
    height: 35px;
    width: 120px;
    border-radius: 4px;
    font-size: 10px;
    font-weight: 400;
    background-color: #1e2631;
    outline: none;
    border: none;
    color: #d1d1d1;
}
.total-sale-chart {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 24px;
    margin: 25px 0px;
    margin-bottom: 0px;
}
.three-component-container {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 24px;
}
