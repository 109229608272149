.container-checkbox {
    display: block;
    position: relative;
    padding-left: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.container-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: transparent;
    border: 1px solid var(--text-icons-gray-200);
}

/* On mouse-over, add a grey background color */
.container-checkbox:hover input ~ .checkmark {
    background-color: #333f50;
}

/* When the checkbox is checked, add a blue background */
.container-checkbox input:checked ~ .checkmark {
    background-color: var(--backgrounds-lines-n-600);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.container-checkbox input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.container-checkbox .checkmark:after {
    left: 4px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.iSLWty:hover:not(:disabled) {
    cursor: pointer;
    background-color: transparent !important;
}

.sc-iqcoie:focus {
    outline: none !important;
    background-color: transparent !important;
}

.sc-ftvSup div:first-child {
    white-space: unset !important;
    overflow: unset !important;
    text-overflow: unset !important;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
    padding: 2px 5px 2px 0px;
    vertical-align: middle !important;
}

.table.child-table .custom-table-width-2 {
    width: 113px !important;
}
